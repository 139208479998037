<template>
	<div>
		<div class="displayFlex flexJCCenter flexWrap">
			<!-- 盒子 -->
			<div v-for="(item, index) in levels" :key="index" @click="checkHandler(item.value)" :class="activeData === item.value ?  'gradeBoxsNormal gradeBoxsRed' : 'gradeBoxsNormal gradeBoxsGray'">
				<span class="mt10px">{{item.value}}</span>
			</div>
		</div>
		<p>{{checkedContent}}</p>
	</div>
</template>

<script>

export default {
	components: {},
	props: {
		// 是否显示p标签
		showMore: {
			type: Boolean,
			default: true
		},
		// 选中的数据
		checkedData: {
			type: String,
			default: ''
		},
		// 是否可点击数据
		ifCancheck: {
			type: Boolean,
			default: true
		}
	},
	data () {
		return {
			// 当前选中的数据
			activeData: '',
			levels: [
				{
					code: 'C1',
					value: 'Conservative',
					content:
						'You are someone who prefers low risk and do not mind lower returns. You prefer a safe and regular income over capital growth. Capital protection of your investment is important to you.'
				},
				{
					code: 'C2',
					value: 'Moderately Conservative',
					content:
						'You are someone who prefers risk with potential returns over medium to long term. Before undertaking any risk, you like to do your research and be prepared as you are unwilling to take chances with your capital.'
				},
				{
					code: 'C3',
					value: 'Balanced',
					content:
						'You are someone who prefers a moderate level of risk. You can tolerate some fluctuations in the value of your investment in anticipation of higher potential returns over medium to long term.'
				},
				{
					code: 'C4',
					value: 'Moderately Aggressive',
					content:
						'You are someone who is willing to accept a higher level of risk, which may result in short term losses. Your main objective is to achieve medium to long term capital growth.'
				},
				{
					code: 'C5',
					value: 'Aggressive',
					content:
						'You are someone who is willing to accept a substantial level of risk. You are prepared to compromise the invested capital to pursue highest possible return over a short to long term investment cycle.'
				}
			],
			checkedContent: 'You are someone who prefers low risk and do not mind lower returns. You prefer a safe and regular income over capital growth. Capital protection of your investment is important to you.',
		};
	},
	computed: {},
	watch: {
		checkedData (val) {
			if (val) {
				this.activeData = this.checkedData
				this.checkedContent = this.levels.filter(n => n.value === this.checkedData)[0].content
			}
		}
	},
	methods: {
		checkHandler (val) {
			if (this.ifCancheck) {
				this.activeData = val
				this.$emit('update:checkedData', val)
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		// 如果父组件传递值 则赋值
		if (this.checkedData) {
			this.activeData = this.checkedData
			this.checkedContent = this.levels.filter(n => n.value === this.checkedData)[0].content
		}
	},
	mounted () {
	}
}
</script>
<style lang='scss' scoped>
.gradeBoxsNormal {
	width: 140px;
	height: 140px;
	margin: 0 10px;
	padding: 0 10px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	h1 {
		font-size: 60px;
	}
	span {
		font-weight: bold;
		font-size: 20px;
	}
}

.gradeBoxsGray {
	border: 2px solid black;
	h1 {
		color: black;
	}
	span {
		color: black;
	}
}

.gradeBoxsRed {
	border: 2px solid red;
	h1 {
		color: red;
	}
	span {
		color: red;
	}
}

p {
	margin-top: 30px;
	font-size: 20px;
	font-weight: bold;
	line-height: 24px;
	letter-spacing: 0px;
	color: #707070;
	text-align:center
}
</style>